.material-icons {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}

.clickable {
  cursor: pointer;
}

.Toastify__toast {
  text-align: start;
}
.Toastify__toast h5 {
  color: #FFFFFF;
}
.Toastify__toast--info {
  background-color: #005F9E
}
.Toastify__toast--success {
  background-color: #4A821F;
}
.Toastify__toast--warning {
  background-color: #FFA500;
}
.Toastify__toast--error {
  background-color: #BC0911;
}

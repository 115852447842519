.admin-container {
    width: calc(100% - 6rem);
    height: calc(100% - 2rem);
    background-color: #FFFFFF;
    z-index: 1;
    margin: 1rem 3rem;
    display: flex;
    flex-direction: column;
}

.admin-content {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    height: 100%;
}

.admin-content-body {
    display: flex;
    flex-direction: column;
    overflow: auto;
}

.admin-header {
    position: relative;
    display: flex;
    justify-content: center;
}

.admin-close {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
}
.touch-controls-icon-container {
    position: absolute;
    right: 1rem;
    bottom: 1rem;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    color: #ffffff;
    background-color: #005F9E;;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    cursor: pointer;
}

.touch-controls-container {
    position: absolute;
    right: 2rem;
    bottom: 2rem;
    width: 160px;
    height: 160px;
    z-index: 1;
}

.touch-controls-container-left {
    position: absolute;
    left: 2rem;
    bottom: 2rem;
    width: 160px;
    height: 160px;
    z-index: 1;
}

.touch-controls-relative-container {
    width: 160px;
    height: 160px;
    position: relative;
}

.close-touch-controls {
    position: absolute;
    right: -26px;
    bottom: -22px;
    width: 40px;
    height: 40px;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    pointer-events: all;
    z-index: 2;
}

.dropup-container {
    position: absolute;
    bottom: -20px;
    display: flex;
    width: 100%;
    z-index: 1000;
    justify-content: center;
}
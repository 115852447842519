.spot-navbar-nav {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: relative;
}

.logo {
    height: 32px;
    position: absolute;
    top: 3px;
    left: 0px;
}

.logo-container {
    display: flex;
    justify-content: flex-start;
}

.nav-right-controls {
    height: 32px;
    position: absolute;
    top: 3px;
    right: 0px;
}

.nav-right-controls > * {
    margin-left: 0.5rem;
}

.nav-icon-button {
    padding: 0;
    border: 0;
}

nav .btn.btn-icon-only.nav-icon-button>.nav-icon {
    line-height: 1;
    font-size: 32px;
}

.large-screen {
    display: flex;
    align-items: center;
}

.small-screen {
    display: none;
}

.navbar-nav .dropdown-menu {
    position: absolute;
}

.dropdown-menu {
    min-width: 200px;
}

@media screen and (max-width: 550px) {
    .logo {
        display: none;
    }

    .large-screen {
        display: none;
    }

    .small-screen {
        display: block;
    }
}
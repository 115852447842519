.video-panel-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1000;
    top: 0;
    left: 0;
    background-color: black;
    opacity: 0.4;
    pointer-events: all;
    display: flex;
    justify-content: center;
    align-items: center;
}

.video-panel-container {
    width: 100%;
    height: 100%;
    position: relative;
    background-color: #363545;
}

.video {
    width: 100%;
    height: 100%;
    border: unset;
    position: relative;
}

.video:focus, .video:hover, .video:active {
    border: unset;
}

.stream-selection-container {
    position: absolute;
    top: 1rem;
    right: 1rem;
}

.framerate-container {
    position: absolute;
    top: 1rem;
    left: 1rem;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.4);
    color: white;
    padding: 0.2rem 0.4rem;
    border-radius: 4px;
    font-size: 12px;
}

.ptz-controls-container {
    position: absolute;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
}

.ptz-controls-content {
    width: 120px;
    height: 120px;
    pointer-events: all;
    display: grid;
    grid-template-columns: 40px 40px 40px;
    grid-template-rows: 40px 40px 40px;
}

.ptz-control-button {
    border-radius: 50%;
}

.viewer-container {
    width: 100%;
    height: 100%;
}
.back-buttton {
    position: absolute;
    left: 0;
    top: -2px;
}

.company-container {
    display: flex;
    flex-direction: row;
    overflow: auto;
}

.company-content {
    flex: 1 0 calc(50% - 1rem);
    display: flex;
    flex-direction: column;
    max-height: 600px;
}
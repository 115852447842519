.admin-btn-container {
    display: flex;
    justify-content: flex-end;
    background-color: #FFFFFF;
    padding: 1rem;
}

.selection-content {
    z-index: 1;
    width: 420px;
    max-height: calc(100% - 2rem);
    display: flex;
    flex-direction: column;
    margin: 1rem;
    box-shadow: -2px 2px 3px 2px rgba(0,0,0,.2);
}

.selection-content-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #F3F3F7;
    padding: 1rem;
}

.selection-content-body {
    height: 100%;
    background: #FFFFFF;
    overflow: auto;
}

.message-container {
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}

.spinner-text-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.spinner-container {
    display: flex;
    align-items: center;
    justify-content: center;
}
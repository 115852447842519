.contoller-container {
    height: 100%;
    width: 100%;
}

.controller-content {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
}

/* Video */
.video-container {
    background-color: #000000;
    width: 100%;
    position: relative;
    grid-gap: 8px;
    overflow: hidden;
}

.grid-2 {
    display: grid;
    grid-template-columns: calc(50% - 4px) calc(50% - 4px);
    grid-template-rows: 100%;
}

.grid-2-horizontal {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: calc(50% - 4px) calc(50% - 4px);
}

.grid-4 {
    display: grid;
    grid-template-columns: calc(50% - 4px) calc(50% - 4px);
    grid-template-rows: calc(50% - 4px) calc(50% - 4px);
}

.sidebar-toggle-icon-container {
    width: 40px;
    height: 40px;
    position: absolute;
    top: calc(50% - 20px);
    left: -20px;
    background-color: #ffffff;
    color: #005F9E;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    cursor: pointer;
    box-shadow: 2px 0px 2px 2px rgba(0,0,0,.1);
    z-index: 1001;
    border-radius: 0 50% 50% 0;
}

.fullscreen-icon-container {
    position: absolute;
    right: 1rem;
    bottom: 4rem;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    color: #ffffff;
    background-color: #005F9E;;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    cursor: pointer;
}
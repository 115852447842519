html, body, #root, .App {
  height: 100%;
  width: 100%;
  margin: 0;
  padding: 0;
}

.svgIcon {
  width: 24px;
  height: 24px;
}

button, button:active, button:hover, button:focus {
  outline: none;
  box-shadow: unset !important;
}

div.__react_component_tooltip {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.list-placeholder-container {
  background-color: #F3F3F7;
  margin: 1rem;
  padding: 1rem;
}

.nipple .back {
  border: 2px solid black;
}

.nipple .front{
  border: 2px solid black;
}
/* Sidebar */
.sidebar-container {
    height: 100%;
    width: 300px;
    flex: 1 0 auto;
    background-color: #ffffff;
    box-shadow: 2px 0px 2px 2px rgba(0,0,0,.1);
    z-index: 10;
    overflow: auto;
}

.sidebar-content {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.sidebar-header {
    background-color: #005F9E;
    color: #ffffff;
    padding: 0.5rem 1rem;
    margin: 0;
    text-align: initial;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 2px 2px 0 rgba(0,0,0,.1);
}

.header-toggle-icon {
    width: 32px;
    height: 32px;
    display: flex;
    color: #ffffff;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.video-tools-container {
    display: flex;
}

.video-tools-icon-container {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: #ffffff;
    color: #005F9E;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-left: 0.5rem;
}

/* Info Container */

.info-container {
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
}

.info-container-full {
    height: calc(100% - 48px);
}

.info-container-70 {
    height: 70%;
}

.info-item {
    display: flex;
    justify-content: space-between;
}

.info-property {
    text-transform: uppercase;
}

.info-value {
    text-transform: capitalize;
    font-weight: bold;
}

/* Battery status */
.battery-item {
    display: flex;
}

.battery-icon-container {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #005F9E;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.power-meter {
    position: absolute;
    top: 13px;
    max-height: 16px;
    width: 6px;
    background-color: #005F9E;
}

.meter-level {
    background-color: #ffffff;
    width: 100%;
}

.battery-text-container {
    padding: 0 0.5rem;
}

.battery-text {
    margin: 0;
    font-weight: bold;
}

/* Controls */
.panel-control-text {
    font-weight: bold;
    margin-bottom: 0.5rem;
}

.panel-control-container {
    display: grid;
    grid-template-columns: calc(50% - 4px) calc(50% - 4px);
    grid-gap: 8px;
    padding-bottom: 1rem;
}

.panel-control-container > * {
    flex: 1;
}

.button-middle {
    margin: 0 1rem;
}

.controls-container {
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
}

.controls-container > button {
    margin: 0 0 1rem 0;
}

.keyboard-container {
    height: 120px;
    padding: 0 40px;
    display: grid;
    grid-template-columns: 33% 33% 33%;
}

.keyboard-key {
    width: 48px;
    height: 48px;
    align-self: center;
    justify-self: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 4px solid #363545;
    box-shadow: 0px 0px 2px 2px rgba(0,0,0,.3);
    font-weight: bold;
    position: relative;
    cursor: pointer;
}

.keyboard-key:hover {
    background-color: #F3F3F7;
}

.keyboard-icon {
    position: absolute;
    top: -2px;
}

.keyboard-icon {
    position: absolute;
    top: -1px;
}

.keyboard-text {
    position: absolute;
    top: 14px;
}

/* Command Log */
.command-log-container {
    display: flex;
    flex-direction: column;
}

.command-log-container-visible {
    height: 30%;
}

.log-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: auto;
    text-align: initial;
    list-style: none;
    padding: 0.5rem 1rem;
}

#instructionLog.log-container {
    justify-content: unset;
}

.log-command {
    font-weight: bold;
}
.fade {
    display: block;
}

.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1040;
    display: block;
    width: 100%;
    height: 100%;
    overflow: hidden;
    outline: 0;
    background-color: #363545;
    opacity: 0.75;
}
.notification-container {
    position: absolute;
    top: 0;
    right: 0;
    width: 400px;
    max-height: 300px;
    overflow: auto;
    background-color: #ffffff;
    z-index: 100;
    box-shadow: -2px 2px 3px 2px rgb(0 0 0 / 20%);
}

.notification-content {
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;
}

.notification-item {
    justify-content: flex-start;
    text-align: start;
    padding: 1rem;
}

.timestamp-span {
    position: absolute;
    right: 8px;
    bottom: 1px;
    font-style: italic;
    font-size: 10px;
    font-weight: bold;
}

.item-title {
    margin-bottom: 0.1rem;
}

.info {
    color: #005F9E
}

.warning {
    color: #FFA500;
}

.error{
    color: #BC0911;
}

.check_circle {
    color: #4A821F;
}

.close-container {
    position: absolute;
    top: 0.2rem;
    right: 0.5rem;
}

@media screen and (max-width: 450px) {
    .notification-container {
        width: 100%;
    }
}
.payload-dialog-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: all;
    z-index: 10000;
}

.dialog-bottom-container {
    position: absolute;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.modal-content {
    width: 400px;
}

.modal-header {
    align-items: center;
}

@media screen and (max-width: 550px) {
    .modal-content {
        width: 100%;
    }
}